import {
 Box,
 Button,
 FormControl,
 FormLabel,
 Input,
 Grid,
 Text,
 Divider,
 HStack,
 Checkbox,
 useToast,
 FormErrorMessage,
 Step,
 StepDescription,
 StepIndicator,
 StepNumber,
 StepSeparator,
 StepStatus,
 StepTitle,
 Stepper,
 useSteps,
 InputGroup,
 InputRightElement,
 IconButton,
 Collapse,
 useDisclosure,
} from "@chakra-ui/react";
import { AttachmentIcon, ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { useState } from "react";
import PrivacyPolicyPage from "./PrivacyPolicyPage"; // Import the Privacy Policy component
import axios from "axios";

// Steps array re-arranged to desired order
const steps = [
 { title: "Step 1", description: "Driver Info" },
 { title: "Step 2", description: "Driver Documents" },
 { title: "Step 3", description: "Car Info" },
 { title: "Step 4", description: "Car Documents" },
 { title: "Step 5", description: "Bank Info (Optional)" },
 { title: "Step 6", description: "Review & Submit" },
];

const RegisterComponent = ({ handleClose }) => {
 const {
  register,
  handleSubmit,
  getValues,
  formState: { errors },
 } = useForm();
 const { activeStep, setActiveStep } = useSteps({ initialStep: 0 });
 const toast = useToast();
 const { isOpen, onToggle } = useDisclosure();
 const [showPassword, setShowPassword] = useState(false); // State to track password visibility
 const [uploadedFiles, setUploadedFiles] = useState({
  profilePicture: null,
  driverLicense: null,
  aadhaarFront: null,
  aadhaarBack: null,
  panCard: null,
  permit: null,
  registrationPaper: null,
  insurance: null,
  pollution: null,
  carFront: null,
  carBack: null,
 });

 const handleTogglePassword = () => setShowPassword(!showPassword);

 // Handle file input change
 const handleFileChange = (e, field) => {
  const file = e.target.files[0];
  setUploadedFiles((prevFiles) => ({ ...prevFiles, [field]: file }));
 };

 const onSubmit = async (data) => {
  const formData = new FormData();

  // Append form fields to formData
  formData.append("driverName", data.driverName);
  formData.append("mobile", data.mobile);
  formData.append("email", data.email);
  formData.append("address", data.address);
  formData.append("password", data.password);
  formData.append("accountHolderName", data.accountHolderName || "");
  formData.append("bankName", data.bankName || "");
  formData.append("ifscCode", data.ifscCode || "");
  formData.append("accountNumber", data.accountNumber || "");
  formData.append("model", data.model);
  formData.append("upiId", data.upiId || "");
  formData.append("carOwnerName", data.carOwnerName);
  formData.append("termsAccepted", data.termsAccepted);

  // Append uploaded files to formData
  Object.keys(uploadedFiles).forEach((key) => {
   if (uploadedFiles[key]) {
    formData.append(key, uploadedFiles[key]);
   }
  });

  try {
   const response = await axios.post(
    "https://backend.theghumoindia.com/api/driver/register",
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
     },
    }
   );

   if (response.status === 200) {
    toast({
     title: "Registration successful.",
     description: "You have registered successfully.",
     status: "success",
     duration: 5000,
     isClosable: true,
    });
   }
  } catch (error) {
   console.error("Error submitting form", error);
   toast({
    title: "Registration failed.",
    description: error.response?.data?.message || "Something went wrong",
    status: "error",
    duration: 5000,
    isClosable: true,
   });
  }
 };

 const nextStep = () => setActiveStep(activeStep + 1);
 const prevStep = () => setActiveStep(activeStep - 1);
 const skipStep = () => setActiveStep(activeStep + 1);

 return (
  <Box>
   <Stepper index={activeStep} mt={5}>
    {steps.map((step, index) => (
     <Step key={index}>
      <StepIndicator>
       <StepStatus
        complete={<StepNumber />}
        incomplete={<StepNumber />}
        active={<StepNumber />}
       />
      </StepIndicator>

      <Box flexShrink="0">
       <StepTitle>{step.title}</StepTitle>
       <StepDescription>{step.description}</StepDescription>
      </Box>

      <StepSeparator />
     </Step>
    ))}
   </Stepper>

   <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "50px" }}>
    {/* Step 1: Driver Info */}
    {activeStep === 0 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Driver Information
      </Text>
      <Divider mb={4} />
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl isInvalid={errors.driverName}>
        <FormLabel>Driver Name</FormLabel>
        <Input
         {...register("driverName", {
          required: "Driver Name is required",
         })}
         placeholder="Enter driver name"
        />
        {errors.driverName && (
         <FormErrorMessage>{errors.driverName.message}</FormErrorMessage>
        )}
       </FormControl>
       <FormControl isInvalid={errors.mobile}>
        <FormLabel>Mobile</FormLabel>
        <Input
         {...register("mobile", {
          required: "Mobile number is required",
          pattern: {
           value: /^[0-9]{10}$/,
           message: "Mobile number must be exactly 10 digits",
          },
         })}
         placeholder="Enter mobile number"
        />
        {errors.mobile && (
         <FormErrorMessage>{errors.mobile.message}</FormErrorMessage>
        )}
       </FormControl>
       <FormControl isInvalid={errors.email}>
        <FormLabel>Email</FormLabel>
        <Input
         {...register("email", {
          required: "Email is required",
          pattern: { value: /^\S+@\S+$/, message: "Invalid email" },
         })}
         placeholder="Enter email"
        />
        {errors.email && (
         <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
       </FormControl>
       <FormControl isInvalid={errors.address}>
        <FormLabel>Address</FormLabel>
        <Input {...register("address")} placeholder="Enter address" />
        {errors.address && (
         <FormErrorMessage>{errors.address.message}</FormErrorMessage>
        )}
       </FormControl>
       <FormControl isInvalid={errors.password}>
        <FormLabel>Password</FormLabel>
        <InputGroup>
         <Input
          type={showPassword ? "text" : "password"}
          {...register("password", { required: "Password required" })}
          placeholder="Enter password"
         />
         <InputRightElement>
          <IconButton
           icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
           onClick={handleTogglePassword}
           variant="ghost"
           aria-label="Toggle Password Visibility"
          />
         </InputRightElement>
        </InputGroup>
        {errors.password && (
         <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
       </FormControl>
      </Grid>
     </Box>
    )}

    {/* Step 2: Driver Documents */}
    {activeStep === 1 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Driver Documents
      </Text>
      <Divider mb={4} />
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl>
        <FormLabel>Driver License</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "driverLicense")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Aadhaar Front</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "aadhaarFront")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Aadhaar Back</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "aadhaarBack")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>PAN Card</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "panCard")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>
      </Grid>
     </Box>
    )}

    {/* Step 3: Car Info */}
    {activeStep === 2 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Car Information
      </Text>
      <Divider mb={4} />
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl isInvalid={errors.model}>
        <FormLabel>Car Model</FormLabel>
        <Input
         {...register("model", { required: "Car model is required" })}
         placeholder="Enter car model"
        />
        {errors.model && (
         <FormErrorMessage>{errors.model.message}</FormErrorMessage>
        )}
       </FormControl>
       <FormControl isInvalid={errors.carOwnerName}>
        <FormLabel>Car Owner Name</FormLabel>
        <Input
         {...register("carOwnerName", {
          required: "Car owner name is required",
         })}
         placeholder="Enter car owner name"
        />
        {errors.carOwnerName && (
         <FormErrorMessage>{errors.carOwnerName.message}</FormErrorMessage>
        )}
       </FormControl>
      </Grid>
     </Box>
    )}

    {/* Step 4: Car Documents */}
    {activeStep === 3 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Car Documents
      </Text>
      <Divider mb={4} />
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl>
        <FormLabel>Permit</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "permit")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Registration Paper</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "registrationPaper")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Insurance</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "insurance")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Pollution Certificate</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "pollution")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Car Front Photo</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "carFront")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>

       <FormControl>
        <FormLabel>Car Back Photo</FormLabel>
        <HStack>
         <Input
          type="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, "carBack")}
         />
         <AttachmentIcon />
        </HStack>
       </FormControl>
      </Grid>
     </Box>
    )}

    {/* Step 5: Bank Info with Skip option */}
    {activeStep === 4 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Bank Information (Optional)
      </Text>
      <Divider mb={4} />
      {/* <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl isInvalid={errors.accountHolderName}>
        <FormLabel>Account Holder Name</FormLabel>
        <Input {...register("accountHolderName")} placeholder="Name" />
       </FormControl>
       <FormControl isInvalid={errors.bankName}>
        <FormLabel>Bank Name</FormLabel>
        <Input {...register("bankName")} placeholder="Bank Name" />
       </FormControl>
      </Grid> */}
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
       <FormControl isInvalid={errors.accountHolderName}>
        <FormLabel>Account Holder Name</FormLabel>
        <Input
         {...register("accountHolderName")}
         placeholder="Enter account holder name"
        />
       </FormControl>
       <FormControl isInvalid={errors.bankName}>
        <FormLabel>Bank Name</FormLabel>
        <Input {...register("bankName")} placeholder="Enter bank name" />
       </FormControl>
       <FormControl isInvalid={errors.ifscCode}>
        <FormLabel>IFSC Code</FormLabel>
        <Input {...register("ifscCode")} placeholder="Enter IFSC code" />
       </FormControl>
       <FormControl isInvalid={errors.accountNumber}>
        <FormLabel>Account Number</FormLabel>
        <Input
         {...register("accountNumber")}
         placeholder="Enter account number"
        />
       </FormControl>
       <FormControl isInvalid={errors.upiId}>
        <FormLabel>UPI ID</FormLabel>
        <Input {...register("upiId")} placeholder="Enter UPI ID" />
       </FormControl>
      </Grid>
      <HStack justify="flex-end" mt={4}>
       <Button onClick={skipStep} colorScheme="yellow" variant="outline">
        Skip
       </Button>
      </HStack>
     </Box>
    )}

    {/* Step 6: Review & Submit */}
    {activeStep === 5 && (
     <Box p={4} borderWidth="1px" borderRadius="lg" mb={4}>
      <Text fontSize="xl" mb={2}>
       Review & Submit
      </Text>
      <Divider mb={4} />
      <FormControl isInvalid={errors.termsAccepted} mt={4}>
       <Checkbox {...register("termsAccepted", { required: true })}>
        I accept the terms and conditions
       </Checkbox>
       {errors.termsAccepted && (
        <FormErrorMessage>
         You must accept the terms and conditions.
        </FormErrorMessage>
       )}
       <Button onClick={onToggle} mt={3} colorScheme="yellow" variant="link">
        View Privacy Policy
       </Button>

       <Collapse in={isOpen} animateOpacity>
        <Box mt={4} p={4} borderWidth="1px" borderRadius="md">
         <PrivacyPolicyPage /> {/* Embed Privacy Policy content here */}
        </Box>
       </Collapse>
      </FormControl>
     </Box>
    )}

    {/* Navigation Buttons */}
    <HStack justify="space-between" mt={6}>
     {activeStep !== 0 && (
      <Button onClick={prevStep} colorScheme="yellow" variant="outline">
       Previous
      </Button>
     )}
     {activeStep < steps.length - 1 ? (
      <Button onClick={nextStep} colorScheme="yellow">
       Next
      </Button>
     ) : (
      <Button type="submit" colorScheme="yellow">
       Submit
      </Button>
     )}
    </HStack>
   </form>
  </Box>
 );
};

export default RegisterComponent;
