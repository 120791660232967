import React from "react";
import {
 Box,
 HStack,
 VStack,
 Image,
 Text,
 Button,
 useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import img3 from "../Images/Group 1171275496.png";
import img4 from "../Images/card.png";
import img5 from "../Images/Mask group.png";
import img6 from "../Images/car-big-side.png.png";

// Reusable component for feature boxes
const FeatureBox = ({ image, title }) => (
 <Box display="flex" alignItems="center">
  <Image src={image} alt={title} boxSize={["50px", "60px", "70px"]} />
  <Text color="black" fontSize={["16px", "20px", "24px"]} ml={4}>
   {title}
  </Text>
 </Box>
);

function Home() {
 const navigate = useNavigate();
 const { isOpen, onOpen, onClose } = useDisclosure();

 // Centralized styles for maintainability
 const styles = {
  container: {
   color: "white",
   width: "100%",
  },
  heroSection: {
   bg: "#FCCD0B",
   w: "100%",
   display: "flex",
   alignItems: "center",
   justifyContent: "space-around",
   flexDirection: ["column", "row"], // Responsive adjustment for mobile view
   padding: [4, 8], // Padding for small and larger screens
  },
  heroText: {
   title: {
    fontSize: ["2xl", "4xl", "5xl"], // Different font sizes for mobile, tablet, and desktop
    fontWeight: "bold",
    color: "white",
    textAlign: ["center", "left"], // Center on small screens, left-align on larger
   },
   subtitle: {
    fontSize: ["lg", "2xl", "3xl"], // Adjust size based on screen width
    color: "white",
    mt: 2,
    textAlign: ["center", "left"],
   },
  },
  aboutSection: {
   bg: "#F8F8F8",
   display: "flex",
   flexDirection: ["column", "row"], // Stack on small screens, row on larger
   p: [4, 8],
  },
  contentBox: {
   w: ["100%", "60vw"], // Full width on mobile, 60% on larger screens
   textAlign: ["center", "left"], // Center text on small screens, left on larger
   mt: [4, 8],
  },
  featureList: {
   spacing: [4, 8],
   wrap: "nowrap",
   justifyContent: "flex-start",
   mt: 4,
   ml: [0, 0, "70px"], // Margin adjustments for different screen sizes
  },
  carImageSection: {
   display: ["none", "none", "flex"], // Hidden on small and medium screens, visible on large
   justifyContent: "center",
   alignItems: "center",
   w: ["100%", "45vw"],
   mt: [4, 0],
  },
  serviceCard: {
   w: ["90vw", "45vw", "30vw"], // Make cards full width on mobile, but reduce size for tablets and desktops
   p: [2, 4], // Padding adjustments for better spacing
   mb: [4, 8], // Space between cards on mobile
   bg: "white",
   borderRadius: "md",
   boxShadow: "md",
  },
 };

 return (
  <Box sx={styles.container}>
   {/* Hero Section */}
   <Box sx={styles.heroSection}>
    <Box>
     <Text sx={styles.heroText.title}>The Ghumo India</Text>
     <Text sx={styles.heroText.subtitle}>
      Your one-stop solution for convenient, reliable, and affordable rides!
     </Text>
    </Box>
    <Image
     src={"/car3.png"}
     alt="Car Image"
     boxSize={["100px", "200px", "300px"]}
    />
   </Box>

   {/* About Us Section */}
   <Box sx={styles.aboutSection}>
    <Box sx={styles.contentBox}>
     <Text color={"#706B6B"} fontSize={["18px", "24px"]} fontWeight={"500"}>
      KNOW ABOUT US
     </Text>
     <Text
      color={"BLACK"}
      fontSize={["19px", "27px", "41px"]}
      fontWeight={"700"}
     >
      WHAT SETS GHUMO INDIA APART?
     </Text>

     {/* First Feature Section */}
     <HStack sx={styles.featureList} wrap="wrap">
      <FeatureBox image={img3} title={"Clean and Hygienic Car"} />
      <FeatureBox image={img3} title={"Expert Chauffeurs"} />
     </HStack>

     <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
      At Ghumo India, we believe in offering unmatched services to both car
      owners and passengers. Our platform bridges the gap between drivers and
      customers, ensuring quality, comfort, and convenience.
     </Text>

     {/* Second Feature Section */}
     <HStack sx={styles.featureList} wrap="wrap">
      <FeatureBox image={img4} title={"Clean and Hygienic Car"} />
      <FeatureBox image={img5} title={"2000+ Cities"} />
     </HStack>

     <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
      - Real-Time Booking Leads: Get instant ride requests from passengers in
      your area. Our platform ensures a steady stream of potential customers.
     </Text>
     <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
      - Easy-to-Use Dashboard: Manage your bookings, track your earnings, and
      see your ride history, all in one place.
     </Text>
     <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
      - Flexible Work Schedule: You decide when to accept rides. Work full-time
      or pick a few rides at your convenience.
     </Text>
     <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
      - Trusted by 2000+ Cities: Extensive network ensures passengers can access
      rides across major cities.
     </Text>
    </Box>

    {/* Car Image Section */}
    <Box sx={styles.carImageSection}>
     <Image src={img6} alt="Car Image" />
    </Box>
   </Box>

   {/* Footer Section */}
  </Box>
 );
}

export default Home;

// import React from "react";
// import { Box, HStack, Image, Text, useDisclosure } from "@chakra-ui/react";
// import { HamburgerIcon } from "@chakra-ui/icons";
// import img1 from "../Images/car3.png";
// import img2 from "../Images/roundcar.png";
// import img3 from "../Images/Group 1171275496.png";
// import img4 from "../Images/card.png";
// import img5 from "../Images/Mask group.png";
// import img6 from "../Images/car-big-side.png.png";
// import { useNavigate } from "react-router-dom";

// function Home() {
//  const navigate = useNavigate();

//  const { isOpen, onOpen, onClose } = useDisclosure();

//  return (
//   <Box color="white" border={"1px solid red"} width={"100%"}>
//    <Box
//     bg="#FCCD0B"
//     w="100%"
//     display={"flex"}
//     alignItems={"center"}
//     justifyContent={"space-around"}
//    >
//     <Box>
//      <Text fontSize={["2xl", "4xl"]} fontWeight="bold" color="white">
//       Carss Cleanser
//      </Text>
//      <Text fontSize={["lg", "2xl"]} color="white" mt={2}>
//       Your ugif needs lite Car carst in stafler requity
//      </Text>
//     </Box>

//     <Image src={"/car3.png"} alt="Car Image" />
//    </Box>
//    <Box
//     bg={"#F8F8F8"}
//     display={"flex"}
//     flexDirection={["column", "row"]}
//     p={[4, 8]}
//     h={""}
//    >
//     <Box
//      w={["96vw", "", "60vw"]}
//      h={["auto", "67vh"]}
//      ml={["0", "0"]}
//      textAlign={["left", "left"]}
//     >
//      <Text
//       color={"#706B6B"}
//       //   fontFamily={"Fira Sans"}
//       fontSize={["18px", "19px", "24px"]}
//       fontWeight={"500"}
//       mt={["5%", "12%"]}
//       ml={["0", "2%"]}
//      >
//       KNOW ABOUT US
//      </Text>
//      <Text
//       color={"BLACK"}
//       //   fontFamily={"Fira Sans"}
//       fontSize={["19px", "21px", "27px", "41px"]}
//       fontWeight={"700"}
//       ml={["0", "2%"]}
//      >
//       WHAT SETS THE GHUMO INDIA APART?
//      </Text>
//      <HStack
//       w={["70vw", "91vw"]}
//       h={["auto", "auto", "17vh"]}
//       spacing={[4, 8]}
//       wrap="nowrap"
//       justifyContent="flex-start"
//       mt={4}
//       ml={{ sm: "", md: "", lg: "80px" }}
//      >
//       <Box display="flex" alignItems="center">
//        <Image src={img3} />
//        <Text color={"black"} fontSize={["18px", "24px"]} ml={4}>
//         Clean and <br /> Hygienic Car
//        </Text>
//       </Box>

//       <Box display="flex" alignItems="center">
//        <Image src={img3} />
//        <Text color={"black"} fontSize={["18px", "2\0px"]} ml={4}>
//         Expert <br /> Chauffeurs
//        </Text>
//       </Box>
//      </HStack>
//      <Text color={"#706B6B"} fontSize={["16px", "18px", "27px"]} mt={4}>
//       Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br />{" "}
//       eiusmod tempor incididunt ut labore et dolore magna aliqua.
//      </Text>
//      <HStack
//       w={["70vw", "91vw"]}
//       h={["auto", "auto", "19vh"]}
//       spacing={[4, 8]}
//       wrap="nowrap"
//       justifyContent="flex-start"
//       mt={4}
//       ml={{ sm: "", md: "", lg: "70px" }}
//      >
//       <Box display={["block", "flex"]} alignItems="center">
//        <Image src={img4} />
//        <Text
//         color={"black"}
//         // fontFamily={"Fira Sans"}
//         fontSize={["18px", "24px"]}
//         ml={4}
//        >
//         Clean and <br /> Hygienic Car
//        </Text>
//       </Box>
//       <Box display={["block", "flex"]} alignItems="center">
//        <Image src={img5} />
//        <Text
//         color={"black"}
//         // fontFamily={"Fira Sans"}
//         fontSize={["18px", "24px"]}
//         ml={4}
//        >
//         2000+ <br />
//         Cities
//        </Text>
//       </Box>
//      </HStack>
//     </Box>
//     <Box
//      display={["none", "none", "flex"]}
//      justifyContent="center"
//      alignItems="center"
//      w={["100%", "45vw"]}
//      mt={["5%", "0"]}
//     >
//      <Image src={img6} />
//     </Box>
//    </Box>
//   </Box>
//  );
// }

// export default Home;
