import React from "react";
import { Box, Text, Image, VStack, HStack, border } from "@chakra-ui/react";
import img1 from "../Images/taxi.png";
import img2 from "../Images/Onwaytrip.png";
import img3 from "../Images/Localrental.png";
import img4 from "../Images/Airporttransfer.png";
import img5 from "../Images/Mask group.png";

function Services() {
 const styles = {
  container: {
   color: "white",
   width: "100%",
   display: "flex",
   flexDirection: ["column", "row"], // Stack vertically on mobile, horizontally on larger screens
  },

  contentBox: {
   w: ["100%", "100vw"], // Full width on mobile, 60% on larger screens
   textAlign: ["center", "left"], // Center text on small screens, left on larger
   mt: [4, 8],
  },
  featureList: {
   spacing: [4, 8],
   wrap: "wrap",
   justifyContent: "space-around",
   mt: 4,
  },
  serviceCard: {
   w: ["90vw", "45vw", "22vw"], // Full width on mobile, reduce size for tablets and desktops
   p: [4], // Padding for cards
   mb: [4, 8], // Space between cards on mobile
   bg: "white",
   borderRadius: "md",
   boxShadow: "md",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   textAlign: "center",
  },
  serviceImage: {
   boxSize: ["80px", "100px"], // Responsive image size
   mb: 4, // Margin below image
  },
 };

 return (
  <Box sx={styles.contentBox}>
   <Text
    fontSize={["19px", "27px", "35px"]}
    fontWeight="600"
    color="black"
    textAlign={"center"}
    mt={8}
   >
    Our Services
   </Text>

   {/* Responsive Layout: Stack Vertically on Mobile, Horizontally on Larger Screens */}
   <HStack
    sx={styles.featureList}
    alignItems="center"
    flexWrap="wrap" // Wraps items on smaller screens
    justifyContent="center" // Center content on smaller screens
   >
    {/* Roundtrip Cabs */}
    <Box sx={styles.serviceCard}>
     <Image src={img4} alt="Roundtrip Cabs" sx={styles.serviceImage} />
     <Text fontWeight="bold" mt={2}>
      Roundtrip Cabs
     </Text>
     <Text fontSize={["sm", "md"]} mt={2}>
      Book a roundtrip cab for your outstation journey. Travel comfortably to
      your destination and back without the hassle of booking two separate
      rides.
     </Text>
    </Box>

    {/* Oneway Drops */}
    <Box sx={styles.serviceCard}>
     <Image src={img5} alt="Oneway Drops" sx={styles.serviceImage} />
     <Text fontWeight="bold" mt={2}>
      Oneway Drops
     </Text>
     <Text fontSize={["sm", "md"]} mt={2}>
      Need a quick one-way ride? Book a one-way cab for a seamless drop to your
      destination, ensuring cost-effective and hassle-free travel.
     </Text>
    </Box>

    {/* Local Rentals */}
    <Box sx={styles.serviceCard}>
     <Image src={img5} alt="Local Rentals" sx={styles.serviceImage} />
     <Text fontWeight="bold" mt={2}>
      Local Rentals
     </Text>
     <Text fontSize={["sm", "md"]} mt={2}>
      Rent a cab locally for a few hours or the entire day. Perfect for business
      meetings, city tours, or running errands, with flexibility in time and
      location.
     </Text>
    </Box>

    {/* Airport Transfers */}
    <Box sx={styles.serviceCard}>
     <Image src={img5} alt="Airport Transfers" sx={styles.serviceImage} />
     <Text fontWeight="bold" mt={2}>
      Airport Transfers
     </Text>
     <Text fontSize={["sm", "md"]} mt={2}>
      Enjoy timely and reliable airport transfers. Book a ride to or from the
      airport with professional drivers, ensuring a stress-free experience every
      time.
     </Text>
    </Box>
   </HStack>
  </Box>
 );
}

export default Services;
