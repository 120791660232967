import React, { useState } from "react";
import {
 Box,
 Input,
 Button,
 Text,
 Stack,
 InputGroup,
 InputLeftElement,
 InputRightElement,
 Link,
 FormControl,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 useDisclosure,
} from "@chakra-ui/react";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import img1 from "../Images/Group 1171275460.png";
import RegisterComponent from "./RegisterComponent";

function Login() {
 const [formData, setFormData] = useState({
  email: "",
  password: "",
 });
 const { isOpen, onOpen, onClose } = useDisclosure(); // For Drawer
 const {
  isOpen: isModalOpen,
  onOpen: onModalOpen,
  onClose: onModalClose,
 } = useDisclosure();
 const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState("");
 const navigate = useNavigate();

 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
   ...prevData,
   [name]: value,
  }));
 };

 const handleSubmit = async () => {
  setLoading(true);
  setError("");
  try {
   const response = await axios.post(
    "https://backend.theghumoindia.com/api/driver/login",
    {
     email: formData.email,
     password: formData.password,
    }
   );
   const token = response.data.token;
   localStorage.setItem("token", token);
   navigate("/live");
  } catch (err) {
   setError("Invalid email or password. Please try again.");
  } finally {
   setLoading(false);
  }
 };

 return (
  <Box
   w={["90vw", "80vw", "55vw"]} // Adjust width for mobile, tablet, and desktop
   h="fit-content"
   p={8}
   boxShadow="lg"
   borderRadius="10px"
   margin="auto"
   mt={["5%", "8%", "8%"]}
   bgGradient="linear(to-r, #FFFBF3, #FFD700)"
   display="flex"
   flexDirection={["column", "row"]} // Stack vertically on mobile, horizontally on larger screens
   alignItems="center"
   justifyContent="center"
  >
   {/* Left Section: Login Form */}
   <Box w={["100%", "100%", "50%"]} p={8}>
    <Text
     textAlign="center"
     fontFamily="Kaisei Decol"
     fontWeight="bold"
     fontSize={["30px", "36px", "44px"]} // Responsive font sizes
     color="black"
    >
     Login
    </Text>
    {error && (
     <Text color="red.500" textAlign="center">
      {error}
     </Text>
    )}
    <Stack spacing={6} mt={8}>
     <InputGroup justifyContent="center">
      <InputLeftElement
       pointerEvents="none"
       children={<FaEnvelope color="gray.400" />}
      />
      <Input
       type="email"
       name="email"
       placeholder="Email"
       border="1px solid black"
       w="full"
       h="50px"
       bg="#FFFFFF"
       fontFamily="Kaisei Decol"
       onChange={handleChange}
       color="black"
       value={formData.email}
      />
     </InputGroup>

     <InputGroup justifyContent="center">
      <InputLeftElement
       pointerEvents="none"
       children={<FaLock color="gray.400" />}
      />
      <Input
       type={showPassword ? "text" : "password"} // Toggle visibility
       name="password"
       placeholder="Password"
       border="1px solid black"
       w="full"
       h="50px"
       bg="#FFFFFF"
       fontFamily="Kaisei Decol"
       onChange={handleChange}
       value={formData.password}
      />
      <InputRightElement width="3rem">
       <Button
        h="1.75rem"
        size="sm"
        onClick={() => setShowPassword(!showPassword)}
       >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
       </Button>
      </InputRightElement>
     </InputGroup>

     <Button
      bg="#FAB519"
      color="black"
      _hover={{ bg: "#FAB519" }}
      w="full"
      h="50px"
      fontSize={["18px", "20px", "22px"]}
      fontFamily="Kaisei Decol"
      onClick={handleSubmit}
      isLoading={loading}
     >
      LOGIN
     </Button>
     {/* Always display the Sign Up button on all screen sizes */}
     <Button
      color="black"
      _hover={{ bg: "#FAB519" }}
      w="full"
      h="50px"
      fontSize={["18px", "20px", "22px"]}
      fontFamily="Kaisei Decol"
      colorScheme="yellow"
      variant="solid"
      onClick={onModalOpen}
     >
      Sign Up
     </Button>

     <Text textAlign="center">
      or register by clicking on sign up in header
     </Text>
    </Stack>
   </Box>

   {/* Right Section: Image */}
   <Box
    w={["100%", "100%", "50%"]} // Full width on mobile, 50% on larger screens
    mt={[8, 8, 0]} // Margin adjustments for smaller screens
    display="flex"
    justifyContent="center"
    alignItems="center"
   >
    <img
     src={img1}
     alt="Login Illustration"
     style={{ width: "100%", maxWidth: "300px" }}
    />
   </Box>

   {/* Modal for Register */}
   <Modal isOpen={isModalOpen} onClose={onModalClose} size="5xl">
    <ModalOverlay />
    <ModalContent>
     <ModalHeader>
      <Text fontSize="2xl">Register Driver</Text>
     </ModalHeader>
     <ModalCloseButton />
     <ModalBody overflowY="auto">
      <RegisterComponent handleClose={onModalClose} />
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
}

export default Login;
