import React, { useState, useEffect } from "react";
import {
 Box,
 Flex,
 Text,
 Heading,
 Select,
 Slider,
 SliderTrack,
 SliderFilledTrack,
 SliderThumb,
 Button,
 Grid,
 GridItem,
 Image,
 VStack,
 Spinner,
 Divider,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 ModalFooter,
 useDisclosure,
 useBreakpointValue,
 Input,
} from "@chakra-ui/react";
import axios from "axios";
import { useDebounce } from "./useDebounce";

const defaultFilters = {
 priceRange: [500, 10000],
 tripType: "",
 vehicleType: "",
 status: "",
};

const RideListingPage = () => {
 const [rides, setRides] = useState([]);
 const [driverData, setDriverData] = useState();
 const [filters, setFilters] = useState(defaultFilters);
 const [loading, setLoading] = useState(false);
 const { isOpen, onOpen, onClose } = useDisclosure();
 const [selectedRide, setSelectedRide] = useState(null);
 const [commissionScreenshot, setCommissionScreenshot] = useState(null);
 const [securityScreenshot, setSecurityScreenshot] = useState(null);
 const token = localStorage.getItem("token");

 const debouncedPriceRange = useDebounce(filters.priceRange, 500);

 // Fetch Rides
 const fetchRides = async () => {
  setLoading(true);
  try {
   const params = {};

   if (debouncedPriceRange) {
    params.minPrice = debouncedPriceRange[0];
    params.maxPrice = debouncedPriceRange[1];
   }
   if (filters.tripType) params.tripType = filters.tripType;
   if (filters.vehicleType) params.vehicleType = filters.vehicleType;
   if (filters.status) params.status = filters.status;

   const res = await axios.get(
    `https://backend.theghumoindia.com/api/driver/rides`,
    {
     params,
    }
   );
   setRides(res.data || []);
  } catch (error) {
   console.error("Error fetching rides:", error);
  } finally {
   setLoading(false);
  }
 };

 // Fetch Driver Profile
 const getDriverProfile = async () => {
  try {
   if (!token) {
    throw new Error("No token found, please log in");
   }

   const response = await axios.get(
    "https://backend.theghumoindia.com/api/driver/profile",
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   );

   if (response.data.success) {
    setDriverData(response.data.driver);
    return response.data.driver;
   } else {
    throw new Error(response.data.message || "Failed to fetch driver details");
   }
  } catch (error) {
   console.error("Error fetching driver profile:", error);
   throw new Error(error.response?.data?.message || "An error occurred");
  }
 };

 useEffect(() => {
  fetchRides();
 }, [
  debouncedPriceRange,
  filters.tripType,
  filters.vehicleType,
  filters.status,
 ]);

 useEffect(() => {
  if (token) {
   getDriverProfile();
  }
 }, []);

 // Handle Ride Acceptance
 const handleAcceptRide = async (rideId) => {
  try {
   const response = await axios.put(
    `https://backend.theghumoindia.com/api/driver/ride/accept/${rideId}`,
    {},
    {
     headers: {
      Authorization: `Bearer ${token}`,
     },
    }
   );
   if (response.data.success) {
    console.log("Ride accepted successfully:", response.data.ride);
    fetchRides(); // Refresh the rides list
   }
  } catch (error) {
   console.error("Error accepting ride:", error);
  }
 };

 // Handle Filter Changes
 const handleFilterChange = (event) => {
  const { name, value } = event.target;
  setFilters((prevFilters) => ({
   ...prevFilters,
   [name]: value,
  }));
 };

 // Handle Price Range Change
 const handlePriceRangeChange = (value) => {
  setFilters((prevFilters) => ({
   ...prevFilters,
   priceRange: value,
  }));
 };

 // Reset Filters
 const resetFilters = () => {
  setFilters(defaultFilters);
 };

 const isAnyFilterApplied = () => {
  return (
   filters.priceRange[0] !== defaultFilters.priceRange[0] ||
   filters.priceRange[1] !== defaultFilters.priceRange[1] ||
   filters.tripType !== defaultFilters.tripType ||
   filters.vehicleType !== defaultFilters.vehicleType ||
   filters.status !== defaultFilters.status
  );
 };

 const flexDirection = useBreakpointValue({ base: "column", md: "row" });
 const filterWidth = useBreakpointValue({ base: "100%", md: "15%" });
 const listWidth = useBreakpointValue({ base: "100%", md: "80%" });

 // Handle Commission Screenshot Upload
 const handleCommissionScreenshotUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
   const previewUrl = URL.createObjectURL(file);
   setCommissionScreenshot({ file, previewUrl });
  }
 };

 // Handle Security Screenshot Upload
 const handleSecurityScreenshotUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
   const previewUrl = URL.createObjectURL(file);
   setSecurityScreenshot({ file, previewUrl });
  }
 };

 // Handle Commission Payment (API call)
 const handleCommissionPayment = async () => {
  if (!commissionScreenshot || !selectedRide) {
   alert("Please upload the commission payment screenshot!");
   return;
  }

  try {
   await updateRideCommissionImage(
    selectedRide._id,
    commissionScreenshot.file,
    driverData._id
   );

   console.log(
    "Commission screenshot uploaded:",
    commissionScreenshot.previewUrl
   );
   onClose();
  } catch (error) {
   console.error("Error uploading commission screenshot:", error);
   alert("Failed to upload commission screenshot. Please try again.");
  }
 };

 // Handle Security Payment (API call)
 const handleSecurityPayment = async () => {
  if (!securityScreenshot || !driverData) {
   alert("Please upload the security payment screenshot!");
   return;
  }

  try {
   await updateDriverSecurityImage(driverData._id, securityScreenshot.file);

   console.log("Security screenshot uploaded:", securityScreenshot.previewUrl);
   onClose();
  } catch (error) {
   console.error("Error uploading security screenshot:", error);
   alert("Failed to upload security screenshot. Please try again.");
  }
 };

 // API call to update ride with the commission image and driver who paid (isPaidby)
 const updateRideCommissionImage = async (rideId, file, driverId) => {
  try {
   const formData = new FormData();
   formData.append("rideCommissionImage", file);
   formData.append("isPaidby", driverId); // Add driverId to track who made the payment

   const response = await axios.put(
    `https://backend.theghumoindia.com/api/driver/ride/${rideId}/update`,
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
     },
    }
   );

   if (response.status === 200) {
    console.log(
     "Commission image and isPaidby updated successfully:",
     response.data
    );
    return response.data;
   }
  } catch (error) {
   console.error("Error updating commission image and isPaidby:", error);
   throw new Error(error.response?.data?.message || "An error occurred");
  }
 };

 // API call to update driver security image
 const updateDriverSecurityImage = async (driverId, file) => {
  try {
   const formData = new FormData();
   formData.append("securityAmountPaidImage", file);

   const response = await axios.put(
    `https://backend.theghumoindia.com/api/driver/${driverId}/update-security-image`,
    formData,
    {
     headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
     },
    }
   );

   if (response.status === 200) {
    console.log("Security payment image updated successfully:", response.data);
    return response.data;
   }
  } catch (error) {
   console.error("Error updating security payment image:", error);
   throw new Error(error.response?.data?.message || "An error occurred");
  }
 };

 // Render Accept or Pay Commission Button
 const renderActionButton = (ride) => {
  if (ride.isPaid && ride.isPaidby === driverData?._id) {
   return (
    <Button
     colorScheme="green"
     width={"250px"}
     alignSelf={{ base: "center", md: "flex-end" }}
     onClick={() => handleAcceptRide(ride._id)}
    >
     {ride.status === "Accepted" ? "Accepted" : "Accept Ride"}
    </Button>
   );
  }

  if (!ride.isPaid) {
   return (
    <Button
     colorScheme="yellow"
     width={"300px"}
     alignSelf={{ base: "center", md: "flex-end" }}
     onClick={() => {
      setSelectedRide(ride); // Set selected ride
      onOpen(); // Open the modal
     }}
    >
     Pay Commission
    </Button>
   );
  }

  return null; // No button if the conditions are not met
 };

 return (
  <Flex direction={flexDirection} p={5}>
   {/* Filters Section */}
   <Box width={filterWidth} borderRight={{ md: "1px solid #ccc" }} p={4}>
    <Heading as="h6" size="md" mb={4}>
     Filters
    </Heading>

    <Text mb={2}>Price Range</Text>
    <Text mb={2}>
     Min: ₹{filters.priceRange[0]} - Max: ₹{filters.priceRange[1]}
    </Text>
    <Slider
     aria-label="slider-ex-1"
     defaultValue={filters.priceRange[1]}
     min={500}
     max={20000}
     value={filters.priceRange[1]}
     onChange={(value) =>
      handlePriceRangeChange([filters.priceRange[0], value])
     }
    >
     <SliderTrack>
      <SliderFilledTrack />
     </SliderTrack>
     <SliderThumb />
    </Slider>

    <Select
     mt={4}
     placeholder="Select Trip Type"
     name="tripType"
     value={filters.tripType}
     onChange={handleFilterChange}
    >
     <option value="One Way">One Way</option>
     <option value="Round Trip">Round Trip</option>
    </Select>

    <Select
     mt={4}
     placeholder="Select Vehicle Type"
     name="vehicleType"
     value={filters.vehicleType}
     onChange={handleFilterChange}
    >
     <option value="Sedan">Sedan</option>
     <option value="SUV">SUV</option>
     <option value="Hatchback">Hatchback</option>
    </Select>

    <Select
     mt={4}
     placeholder="Select Status"
     name="status"
     value={filters.status}
     onChange={handleFilterChange}
    >
     <option value="Pending">Pending</option>
     <option value="Completed">Completed</option>
     <option value="Cancelled">Cancelled</option>
    </Select>

    {isAnyFilterApplied() && (
     <Button mt={4} colorScheme="red" onClick={resetFilters}>
      Reset Filters
     </Button>
    )}
   </Box>

   {/* Rides Section */}
   <Box width={listWidth} p={4}>
    <Heading as="h6" size="md" mb={4}>
     Rides
    </Heading>
    {loading ? (
     <Spinner />
    ) : (
     <Grid
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={6}
     >
      {rides.length > 0 ? (
       rides.map((ride) => (
        <GridItem key={ride._id}>
         <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p={4}
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
          justifyContent="space-between"
         >
          <Image
           boxSize={{ base: "100px", md: "150px" }}
           src={ride.carTypeImage ? ride.carTypeImage : "/car3.png"}
           alt="Ride Image"
           mb={{ base: 4, md: 0 }}
          />

          <VStack
           align={{ base: "center", md: "flex-start" }}
           spacing={2}
           flex="1"
           ml={{ md: 4 }}
           textAlign={{ base: "center", md: "left" }}
          >
           <Text fontWeight="bold">
            {ride.bookingId} - {ride.vehicleType}
           </Text>
           <Text>Source: {ride.source}</Text>
           <Text>
            Destination:{" "}
            {Array.isArray(ride.destination)
             ? ride.destination.join(", ")
             : ride.destination}
           </Text>
           <Text>Start Time: {new Date(ride.startTime).toLocaleString()}</Text>
           <Text>Status: {ride.status}</Text>
          </VStack>

          <VStack
           align={{ base: "center", md: "flex-start" }}
           spacing={2}
           textAlign={{ base: "center", md: "left" }}
           ml={{ md: 4 }}
           mt={{ base: 4, md: 0 }}
          >
           <Text fontWeight="bold" color="teal">
            ₹{ride.totalCollect}
           </Text>
           <Text>Vendor Price: ₹{ride.vendorPrice}</Text>
           <Text>Commission: ₹{ride.commission}</Text>
          </VStack>

          {renderActionButton(ride)}
         </Box>
         <Divider my={4} />
        </GridItem>
       ))
      ) : (
       <Text>No rides available</Text>
      )}
     </Grid>
    )}

    {/* Modal for commission payment if the driver is approved */}
    {driverData?.isApproved && selectedRide && (
     <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
       <ModalHeader>Commission Payment Required</ModalHeader>
       <ModalCloseButton />
       <ModalBody>
        <Text fontSize="lg" mb={4}>
         You are required to pay a commission of ₹{selectedRide.commission} for
         this ride. Please upload the payment screenshot to proceed.
        </Text>
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
         <Image
          src="/payqr.jpg"
          alt="QR Code"
          boxSize="250px"
          objectFit="cover"
          mb={4}
         />
         {!commissionScreenshot ? (
          <Input
           type="file"
           accept="image/*"
           onChange={handleCommissionScreenshotUpload}
           mb={4}
          />
         ) : (
          <Image
           src={commissionScreenshot.previewUrl}
           alt="Uploaded Screenshot"
           boxSize="250px"
           objectFit="cover"
           mb={4}
           border="1px solid #ccc"
           borderRadius="md"
          />
         )}
        </Box>
       </ModalBody>
       <ModalFooter>
        <Button colorScheme="yellow" mr={3} onClick={handleCommissionPayment}>
         Upload and Proceed
        </Button>
        <Button colorScheme="red" mr={3} onClick={onClose}>
         Close
        </Button>
       </ModalFooter>
      </ModalContent>
     </Modal>
    )}

    {/* Modal for security deposit if the driver is not approved */}
    {!driverData?.isApproved && (
     <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
       <ModalHeader>Security Payment Required</ModalHeader>
       <ModalCloseButton />
       <ModalBody>
        <Text fontSize="lg" mb={4}>
         To proceed with accepting this ride, you are required to pay a security
         deposit of ₹1000. Please scan the QR code below to make the payment.
        </Text>
        <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
         <Image
          src="/payqr.jpg"
          alt="QR Code"
          boxSize="250px"
          objectFit="cover"
          mb={4}
         />
         {!securityScreenshot ? (
          <Input
           type="file"
           accept="image/*"
           onChange={handleSecurityScreenshotUpload}
           mb={4}
          />
         ) : (
          <Image
           src={securityScreenshot.previewUrl}
           alt="Uploaded Screenshot"
           boxSize="250px"
           objectFit="cover"
           mb={4}
           border="1px solid #ccc"
           borderRadius="md"
          />
         )}
        </Box>
       </ModalBody>
       <ModalFooter>
        <Button colorScheme="yellow" mr={3} onClick={handleSecurityPayment}>
         Upload and Proceed
        </Button>
        <Button colorScheme="red" mr={3} onClick={onClose}>
         Close
        </Button>
       </ModalFooter>
      </ModalContent>
     </Modal>
    )}
   </Box>
  </Flex>
 );
};

export default RideListingPage;

// import React, { useState, useEffect } from "react";
// import {
//  Box,
//  Flex,
//  Text,
//  Heading,
//  Select,
//  Slider,
//  SliderTrack,
//  SliderFilledTrack,
//  SliderThumb,
//  Button,
//  Grid,
//  GridItem,
//  Image,
//  VStack,
//  Spinner,
//  Divider,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalCloseButton,
//  ModalBody,
//  ModalFooter,
//  useDisclosure,
//  useBreakpointValue,
//  Input,
// } from "@chakra-ui/react";
// import axios from "axios";
// import { useDebounce } from "./useDebounce";

// const defaultFilters = {
//  priceRange: [500, 10000],
//  tripType: "",
//  vehicleType: "",
//  status: "",
// };

// const RideListingPage = () => {
//  const [rides, setRides] = useState([]);
//  const [driverData, setDriverData] = useState();
//  const [filters, setFilters] = useState(defaultFilters);
//  const [loading, setLoading] = useState(false);
//  const { isOpen, onOpen, onClose } = useDisclosure();
//  const [selectedRide, setSelectedRide] = useState(null); // Store selected ride details
//  const [commissionScreenshot, setCommissionScreenshot] = useState(null); // Store the commission screenshot
//  const [securityScreenshot, setSecurityScreenshot] = useState(null); // Store the security screenshot
//  const token = localStorage.getItem("token");

//  const debouncedPriceRange = useDebounce(filters.priceRange, 500);

//  // Fetch Rides
//  const fetchRides = async () => {
//   setLoading(true);
//   try {
//    const params = {};

//    if (debouncedPriceRange) {
//     params.minPrice = debouncedPriceRange[0];
//     params.maxPrice = debouncedPriceRange[1];
//    }
//    if (filters.tripType) params.tripType = filters.tripType;
//    if (filters.vehicleType) params.vehicleType = filters.vehicleType;
//    if (filters.status) params.status = filters.status;

//    const res = await axios.get(`https://backend.theghumoindia.com/api/driver/rides`, {
//     params,
//    });
//    setRides(res.data || []);
//   } catch (error) {
//    console.error("Error fetching rides:", error);
//   } finally {
//    setLoading(false);
//   }
//  };

//  // Fetch Driver Profile
//  const getDriverProfile = async () => {
//   try {
//    if (!token) {
//     throw new Error("No token found, please log in");
//    }

//    const response = await axios.get(
//     "https://backend.theghumoindia.com/api/driver/profile",
//     {
//      headers: {
//       Authorization: `Bearer ${token}`,
//      },
//     }
//    );

//    if (response.data.success) {
//     setDriverData(response.data.driver);
//     return response.data.driver;
//    } else {
//     throw new Error(response.data.message || "Failed to fetch driver details");
//    }
//   } catch (error) {
//    console.error("Error fetching driver profile:", error);
//    throw new Error(error.response?.data?.message || "An error occurred");
//   }
//  };

//  useEffect(() => {
//   fetchRides();
//  }, [
//   debouncedPriceRange,
//   filters.tripType,
//   filters.vehicleType,
//   filters.status,
//  ]);

//  useEffect(() => {
//   if (token) {
//    getDriverProfile();
//   }
//  }, []);

//  // Handle Filter Changes
//  const handleFilterChange = (event) => {
//   const { name, value } = event.target;
//   setFilters((prevFilters) => ({
//    ...prevFilters,
//    [name]: value,
//   }));
//  };

//  // Handle Price Range Change
//  const handlePriceRangeChange = (value) => {
//   setFilters((prevFilters) => ({
//    ...prevFilters,
//    priceRange: value,
//   }));
//  };

//  // Reset Filters
//  const resetFilters = () => {
//   setFilters(defaultFilters);
//  };

//  const isAnyFilterApplied = () => {
//   return (
//    filters.priceRange[0] !== defaultFilters.priceRange[0] ||
//    filters.priceRange[1] !== defaultFilters.priceRange[1] ||
//    filters.tripType !== defaultFilters.tripType ||
//    filters.vehicleType !== defaultFilters.vehicleType ||
//    filters.status !== defaultFilters.status
//   );
//  };

//  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
//  const filterWidth = useBreakpointValue({ base: "100%", md: "15%" });
//  const listWidth = useBreakpointValue({ base: "100%", md: "80%" });

//  // Handle Ride Acceptance
//  const handleAcceptRide = (ride) => {
//   setSelectedRide(ride);
//   onOpen();
//  };

//  // Handle Commission Screenshot Upload
//  const handleCommissionScreenshotUpload = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//    const previewUrl = URL.createObjectURL(file);
//    setCommissionScreenshot({ file, previewUrl });
//   }
//  };

//  // Handle Security Screenshot Upload
//  const handleSecurityScreenshotUpload = (event) => {
//   const file = event.target.files[0];
//   if (file) {
//    const previewUrl = URL.createObjectURL(file);
//    setSecurityScreenshot({ file, previewUrl });
//   }
//  };

//  // Handle Commission Payment (API call)
//  const handleCommissionPayment = async () => {
//   if (!commissionScreenshot || !selectedRide) {
//    alert("Please upload the commission payment screenshot!");
//    return;
//   }

//   try {
//    // Call the API to upload the commission screenshot and update the isPaidby field
//    await updateRideCommissionImage(
//     selectedRide._id,
//     commissionScreenshot.file,
//     driverData._id
//    );

//    console.log(
//     "Commission screenshot uploaded:",
//     commissionScreenshot.previewUrl
//    );
//    onClose();
//   } catch (error) {
//    console.error("Error uploading commission screenshot:", error);
//    alert("Failed to upload commission screenshot. Please try again.");
//   }
//  };

//  // Handle Security Payment (API call)
//  const handleSecurityPayment = async () => {
//   if (!securityScreenshot || !driverData) {
//    alert("Please upload the security payment screenshot!");
//    return;
//   }

//   try {
//    // Call the API to upload the security screenshot
//    await updateDriverSecurityImage(driverData._id, securityScreenshot.file);

//    console.log("Security screenshot uploaded:", securityScreenshot.previewUrl);
//    onClose();
//   } catch (error) {
//    console.error("Error uploading security screenshot:", error);
//    alert("Failed to upload security screenshot. Please try again.");
//   }
//  };

//  // API call to update ride with the commission image and driver who paid (isPaidby)
//  // API call to update ride with the commission image and driver who paid (isPaidby)
//  const updateRideCommissionImage = async (rideId, file, driverId) => {
//   try {
//    const formData = new FormData();
//    formData.append("rideCommissionImage", file);
//    formData.append("isPaidby", driverId); // Add driverId to track who made the payment

//    // Make the API call to update the commission image and isPaidby field
//    const response = await axios.put(
//     `https://backend.theghumoindia.com/api/driver/ride/${rideId}/update`,
//     formData,
//     {
//      headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${token}`, // Include the token for authentication
//      },
//     }
//    );

//    if (response.status === 200) {
//     console.log(
//      "Commission image and isPaidby updated successfully:",
//      response.data
//     );
//     return response.data;
//    }
//   } catch (error) {
//    console.error("Error updating commission image and isPaidby:", error);
//    throw new Error(error.response?.data?.message || "An error occurred");
//   }
//  };

//  // API call to update driver security image
//  const updateDriverSecurityImage = async (driverId, file) => {
//   try {
//    const formData = new FormData();
//    formData.append("securityAmountPaidImage", file);

//    // Make the API call to update the security payment image
//    const response = await axios.put(
//     `https://backend.theghumoindia.com/api/driver/${driverId}/update-security-image`,
//     formData,
//     {
//      headers: {
//       "Content-Type": "multipart/form-data",
//       Authorization: `Bearer ${token}`, // Include the token for authentication
//      },
//     }
//    );

//    if (response.status === 200) {
//     console.log("Security payment image updated successfully:", response.data);
//     return response.data;
//    }
//   } catch (error) {
//    console.error("Error updating security payment image:", error);
//    throw new Error(error.response?.data?.message || "An error occurred");
//   }
//  };

//  return (
//   <Flex direction={flexDirection} p={5}>
//    {/* Filters Section */}
//    <Box width={filterWidth} borderRight={{ md: "1px solid #ccc" }} p={4}>
//     <Heading as="h6" size="md" mb={4}>
//      Filters
//     </Heading>

//     <Text mb={2}>Price Range</Text>
//     <Text mb={2}>
//      Min: ₹{filters.priceRange[0]} - Max: ₹{filters.priceRange[1]}
//     </Text>
//     <Slider
//      aria-label="slider-ex-1"
//      defaultValue={filters.priceRange[1]}
//      min={500}
//      max={20000}
//      value={filters.priceRange[1]}
//      onChange={(value) =>
//       handlePriceRangeChange([filters.priceRange[0], value])
//      }
//     >
//      <SliderTrack>
//       <SliderFilledTrack />
//      </SliderTrack>
//      <SliderThumb />
//     </Slider>

//     <Select
//      mt={4}
//      placeholder="Select Trip Type"
//      name="tripType"
//      value={filters.tripType}
//      onChange={handleFilterChange}
//     >
//      <option value="One Way">One Way</option>
//      <option value="Round Trip">Round Trip</option>
//     </Select>

//     <Select
//      mt={4}
//      placeholder="Select Vehicle Type"
//      name="vehicleType"
//      value={filters.vehicleType}
//      onChange={handleFilterChange}
//     >
//      <option value="Sedan">Sedan</option>
//      <option value="SUV">SUV</option>
//      <option value="Hatchback">Hatchback</option>
//     </Select>

//     <Select
//      mt={4}
//      placeholder="Select Status"
//      name="status"
//      value={filters.status}
//      onChange={handleFilterChange}
//     >
//      <option value="Pending">Pending</option>
//      <option value="Completed">Completed</option>
//      <option value="Cancelled">Cancelled</option>
//     </Select>

//     {isAnyFilterApplied() && (
//      <Button mt={4} colorScheme="red" onClick={resetFilters}>
//       Reset Filters
//      </Button>
//     )}
//    </Box>

//    {/* Rides Section */}
//    <Box width={listWidth} p={4}>
//     <Heading as="h6" size="md" mb={4}>
//      Rides
//     </Heading>
//     {loading ? (
//      <Spinner />
//     ) : (
//      <Grid
//       templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
//       gap={6}
//      >
//       {rides.length > 0 ? (
//        rides.map((ride) => (
//         <GridItem key={ride._id}>
//          <Box
//           borderWidth="1px"
//           borderRadius="lg"
//           overflow="hidden"
//           p={4}
//           display="flex"
//           flexDirection={{ base: "column", md: "row" }}
//           alignItems={{ base: "center", md: "flex-start" }}
//           justifyContent="space-between"
//          >
//           <Image
//            boxSize={{ base: "100px", md: "150px" }}
//            src={ride.carTypeImage ? ride.carTypeImage : "/car3.png"}
//            alt="Ride Image"
//            mb={{ base: 4, md: 0 }}
//           />

//           <VStack
//            align={{ base: "center", md: "flex-start" }}
//            spacing={2}
//            flex="1"
//            ml={{ md: 4 }}
//            textAlign={{ base: "center", md: "left" }}
//           >
//            <Text fontWeight="bold">
//             {ride.bookingId} - {ride.vehicleType}
//            </Text>
//            <Text>Source: {ride.source}</Text>
//            <Text>
//             Destination:{" "}
//             {Array.isArray(ride.destination)
//              ? ride.destination.join(", ")
//              : ride.destination}
//            </Text>
//            <Text>Start Time: {new Date(ride.startTime).toLocaleString()}</Text>
//            <Text>Status: {ride.status}</Text>
//           </VStack>

//           <VStack
//            align={{ base: "center", md: "flex-start" }}
//            spacing={2}
//            textAlign={{ base: "center", md: "left" }}
//            ml={{ md: 4 }}
//            mt={{ base: 4, md: 0 }}
//           >
//            <Text fontWeight="bold" color="teal">
//             ₹{ride.totalCollect}
//            </Text>
//            <Text>Vendor Price: ₹{ride.vendorPrice}</Text>
//            <Text>Commission: ₹{ride.commission}</Text>
//           </VStack>
//           {ride?.isPaid ? (
//            <Button
//             colorScheme="yellow"
//             mt={{ base: 4, md: 0 }}
//             ml={{ md: 4 }}
//             alignSelf={{ base: "center", md: "flex-end" }}
//             onClick={() => handleAcceptRide(ride)}
//            >
//             Accept Ride
//            </Button>
//           ) : (
//            <Button
//             colorScheme="yellow"
//             mt={{ base: 4, md: 0 }}
//             ml={{ md: 4 }}
//             alignSelf={{ base: "center", md: "flex-end" }}
//             onClick={() => handleAcceptRide(ride)}
//            >
//             Pay Commission
//            </Button>
//           )}
//          </Box>
//          <Divider my={4} />
//         </GridItem>
//        ))
//       ) : (
//        <Text>No rides available</Text>
//       )}
//      </Grid>
//     )}

//     {/* Modal for commission payment if the driver is approved */}
//     {driverData?.isApproved && selectedRide && (
//      <Modal isOpen={isOpen} onClose={onClose} size="lg">
//       <ModalOverlay />
//       <ModalContent>
//        <ModalHeader>Commission Payment Required</ModalHeader>
//        <ModalCloseButton />
//        <ModalBody>
//         <Text fontSize="lg" mb={4}>
//          You are required to pay a commission of ₹{selectedRide.commission} for
//          this ride. Please upload the payment screenshot to proceed.
//         </Text>
//         <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
//          <Image
//           src="/payqr.jpg"
//           alt="QR Code"
//           boxSize="250px"
//           objectFit="cover"
//           mb={4}
//          />
//          {!commissionScreenshot ? (
//           <Input
//            type="file"
//            accept="image/*"
//            onChange={handleCommissionScreenshotUpload}
//            mb={4}
//           />
//          ) : (
//           <Image
//            src={commissionScreenshot.previewUrl}
//            alt="Uploaded Screenshot"
//            boxSize="250px"
//            objectFit="cover"
//            mb={4}
//            border="1px solid #ccc"
//            borderRadius="md"
//           />
//          )}
//         </Box>
//        </ModalBody>
//        <ModalFooter>
//         <Button colorScheme="yellow" mr={3} onClick={handleCommissionPayment}>
//          Upload and Proceed
//         </Button>
//         <Button colorScheme="red" mr={3} onClick={onClose}>
//          Close
//         </Button>
//        </ModalFooter>
//       </ModalContent>
//      </Modal>
//     )}

//     {/* Modal for security deposit if the driver is not approved */}
//     {!driverData?.isApproved && (
//      <Modal isOpen={isOpen} onClose={onClose} size="lg">
//       <ModalOverlay />
//       <ModalContent>
//        <ModalHeader>Security Payment Required</ModalHeader>
//        <ModalCloseButton />
//        <ModalBody>
//         <Text fontSize="lg" mb={4}>
//          To proceed with accepting this ride, you are required to pay a security
//          deposit of ₹1000. Please scan the QR code below to make the payment.
//         </Text>
//         <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
//          <Image
//           src="/payqr.jpg"
//           alt="QR Code"
//           boxSize="250px"
//           objectFit="cover"
//           mb={4}
//          />
//          {!securityScreenshot ? (
//           <Input
//            type="file"
//            accept="image/*"
//            onChange={handleSecurityScreenshotUpload}
//            mb={4}
//           />
//          ) : (
//           <Image
//            src={securityScreenshot.previewUrl}
//            alt="Uploaded Screenshot"
//            boxSize="250px"
//            objectFit="cover"
//            mb={4}
//            border="1px solid #ccc"
//            borderRadius="md"
//           />
//          )}
//         </Box>
//        </ModalBody>
//        <ModalFooter>
//         <Button colorScheme="yellow" mr={3} onClick={handleSecurityPayment}>
//          Upload and Proceed
//         </Button>
//         <Button colorScheme="red" mr={3} onClick={onClose}>
//          Close
//         </Button>
//        </ModalFooter>
//       </ModalContent>
//      </Modal>
//     )}
//    </Box>
//   </Flex>
//  );
// };

// export default RideListingPage;
