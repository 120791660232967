import { HamburgerIcon } from "@chakra-ui/icons";
import {
 Box,
 Button,
 Drawer,
 DrawerBody,
 DrawerCloseButton,
 DrawerContent,
 DrawerHeader,
 DrawerOverlay,
 HStack,
 IconButton,
 Image,
 Text,
 useDisclosure,
 VStack,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalBody,
 ModalCloseButton,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 MenuGroup,
 MenuDivider,
 Avatar,
} from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterComponent from "./RegisterComponent"; // Import the RegisterComponent

const Navbar = () => {
 const navigate = useNavigate();
 const { isOpen, onOpen, onClose } = useDisclosure(); // For Drawer
 const token = localStorage.getItem("token"); // Check for the token in localStorage

 const {
  isOpen: isModalOpen,
  onOpen: onModalOpen,
  onClose: onModalClose,
 } = useDisclosure(); // For Modal

 const handleLogout = async () => {
  await localStorage.clear();
  window.location.reload();
 };

 // Function to handle navigation and drawer close
 const handleNavigateAndClose = (path) => {
  navigate(path);
  onClose(); // Close the drawer after navigation
 };

 return (
  <Box w="100%" bg="white" boxShadow="lg">
   {/* Main Navigation Bar */}
   <HStack
    justify="space-between"
    p={4}
    align="center"
    w="100%"
    maxW="1200px"
    mx="auto"
   >
    {/* Left side - Logo */}
    <HStack spacing={6} align="center">
     {/* Wrap the logo image with the Link component */}
     <Link to="/" _hover={{ color: "yellow.500" }}>
      <Image
       src={"/roundcar.png"} // Replace with the correct logo path
       alt="Logo"
       w={["50px", "80px"]}
       h={["50px", "80px"]}
       display={{ base: "block" }}
       cursor="pointer"
      />
     </Link>

     {/* Main Links - Hidden on mobile */}
     <HStack
      spacing={8}
      display={{ base: "none", md: "flex" }}
      fontSize="lg"
      fontWeight="bold"
     >
      <Link to="/" _hover={{ color: "yellow.500" }}>
       Home
      </Link>
      <Link to="/live" _hover={{ color: "yellow.500" }}>
       Live Ride
      </Link>
      <Link to="/completed" _hover={{ color: "yellow.500" }}>
       Completed Ride
      </Link>
      <Button variant="link" onClick={() => navigate("/contact")} color="black">
       Contact Us
      </Button>
     </HStack>
    </HStack>

    {/* Right side - Avatar/Profile Menu */}
    {token ? (
     <Menu>
      <MenuButton as={Button} variant="link">
       <Avatar size="md" name="Driver Name" src="/path/to/avatar.png" />
      </MenuButton>
      <MenuList>
       <MenuGroup title="Profile">
        <MenuItem>My Account</MenuItem>
        <MenuItem>Payments</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
       </MenuGroup>
       <MenuDivider />
      </MenuList>
     </Menu>
    ) : null}

    {/* Hamburger Menu - Visible on mobile */}
    <IconButton
     icon={<HamburgerIcon />}
     colorScheme="yellow"
     variant="outline"
     display={{ base: "block", md: "none" }}
     onClick={onOpen}
    />
   </HStack>

   {/* Drawer for Mobile Menu */}
   <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
    <DrawerOverlay />
    <DrawerContent>
     <DrawerCloseButton />
     <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
     <DrawerBody>
      <VStack spacing={4} align="flex-start">
       <Button variant="link" onClick={() => handleNavigateAndClose("/")}>
        Home
       </Button>
       <Button variant="link" onClick={() => handleNavigateAndClose("/live")}>
        Live Ride
       </Button>
       <Button
        variant="link"
        onClick={() => handleNavigateAndClose("/completed")}
       >
        Completed Ride
       </Button>
       <Button
        colorScheme="yellow"
        onClick={() => handleNavigateAndClose("/login")}
       >
        {/* Contact Us */} Login
       </Button>
      </VStack>
     </DrawerBody>
    </DrawerContent>
   </Drawer>

   {/* Modal for Register */}
   <Modal isOpen={isModalOpen} onClose={onModalClose} size="5xl">
    <ModalOverlay />
    <ModalContent>
     <ModalHeader>
      <Text fontSize="2xl">Register Driver</Text>
     </ModalHeader>
     <ModalCloseButton />
     <ModalBody overflowY="auto">
      <RegisterComponent handleClose={onModalClose} />
     </ModalBody>
    </ModalContent>
   </Modal>
  </Box>
 );
};

export default Navbar;

// import { HamburgerIcon } from "@chakra-ui/icons";
// import {
//  Box,
//  Button,
//  Drawer,
//  DrawerBody,
//  DrawerCloseButton,
//  DrawerContent,
//  DrawerHeader,
//  DrawerOverlay,
//  HStack,
//  IconButton,
//  Image,
//  Text,
//  useDisclosure,
//  VStack,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalBody,
//  ModalCloseButton,
//  Menu,
//  MenuButton,
//  MenuList,
//  MenuItem,
//  MenuGroup,
//  MenuDivider,
//  Avatar,
// } from "@chakra-ui/react";
// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import RegisterComponent from "./RegisterComponent"; // Import the RegisterComponent

// const Navbar = () => {
//  const navigate = useNavigate();
//  const { isOpen, onOpen, onClose } = useDisclosure(); // For Drawer
//  const token = localStorage.getItem("token"); // Check for the token in localStorage

//  const {
//   isOpen: isModalOpen,
//   onOpen: onModalOpen,
//   onClose: onModalClose,
//  } = useDisclosure(); // For Modal

//  const handleLogout = async () => {
//   await localStorage.clear();
//   await window.location.reload();
//  };

//  // Function to handle navigation and drawer close
//  const handleNavigateAndClose = (path) => {
//   navigate(path);
//   onClose(); // Close the drawer after navigation
//  };

//  return (
//   <Box w="100%" bg="white" boxShadow="lg">
//    {/* Main Navigation Bar */}
//    <HStack
//     justify="space-between"
//     p={4}
//     align="center"
//     w="100%"
//     maxW="1200px"
//     mx="auto"
//    >
//     {/* Left side - Logo */}
//     <HStack spacing={6} align="center">
//      <Link to="/" _hover={{ color: "yellow.500" }}>
//       <Image
//        src={"/roundcar.png"} // Replace with the correct logo path
//        alt="Logo"
//        w={["50px", "80px"]}
//        h={["50px", "80px"]}
//        display={{ base: "block" }}
//       />
//      </Link>
//      {/* Main Links - Hidden on mobile */}
//      <HStack
//       spacing={8}
//       display={{ base: "none", md: "flex" }}
//       fontSize="lg"
//       fontWeight="bold"
//      >
//       <Link to="/" _hover={{ color: "yellow.500" }}>
//        Home
//       </Link>
//       <Link to="/live" _hover={{ color: "yellow.500" }}>
//        Live Ride
//       </Link>
//       <Link to="/completed" _hover={{ color: "yellow.500" }}>
//        Completed Ride
//       </Link>
//       <Button variant="link" onClick={() => navigate("/contact")} color="black">
//        Contact Us
//       </Button>
//      </HStack>
//     </HStack>

//     {/* Right side - Avatar/Profile Menu */}
//     {token ? (
//      <Menu>
//       <MenuButton as={Button} variant="link">
//        <Avatar size="md" name="Driver Name" src="/path/to/avatar.png" />
//       </MenuButton>
//       <MenuList>
//        <MenuGroup title="Profile">
//         <MenuItem>My Account</MenuItem>
//         <MenuItem>Payments</MenuItem>
//         <MenuItem onClick={handleLogout}>Logout</MenuItem>
//        </MenuGroup>
//        <MenuDivider />
//       </MenuList>
//      </Menu>
//     ) : null}

//     {/* Hamburger Menu - Visible on mobile */}
//     <IconButton
//      icon={<HamburgerIcon />}
//      colorScheme="yellow"
//      variant="outline"
//      display={{ base: "block", md: "none" }}
//      onClick={onOpen}
//     />
//    </HStack>

//    {/* Drawer for Mobile Menu */}
//    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
//     <DrawerOverlay />
//     <DrawerContent>
//      <DrawerCloseButton />
//      <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
//      <DrawerBody>
//       <VStack spacing={4} align="flex-start">
//        <Button variant="link" onClick={() => handleNavigateAndClose("/")}>
//         Home
//        </Button>
//        <Button variant="link" onClick={() => handleNavigateAndClose("/live")}>
//         Live Ride
//        </Button>
//        <Button
//         variant="link"
//         onClick={() => handleNavigateAndClose("/completed")}
//        >
//         Completed Ride
//        </Button>
//        <Button
//         colorScheme="yellow"
//         onClick={() => handleNavigateAndClose("/contact")}
//        >
//         Contact Us
//        </Button>
//        {token ? (
//         <Text>Hello Driver</Text>
//        ) : (
//         <Button onClick={onModalOpen}>Login</Button>
//        )}
//       </VStack>
//      </DrawerBody>
//     </DrawerContent>
//    </Drawer>

//    {/* Modal for Register */}
//    <Modal isOpen={isModalOpen} onClose={onModalClose} size="5xl">
//     <ModalOverlay />
//     <ModalContent>
//      <ModalHeader>
//       <Text fontSize="2xl">Register Driver</Text>
//      </ModalHeader>
//      <ModalCloseButton />
//      <ModalBody overflowY="auto">
//       <RegisterComponent handleClose={onModalClose} />
//      </ModalBody>
//     </ModalContent>
//    </Modal>
//   </Box>
//  );
// };

// export default Navbar;
