import React from "react";
import { Box, Text, Heading, Divider } from "@chakra-ui/react";

const PrivacyPolicyPage = () => {
 return (
  <Box maxW="1000px" mx="auto" p={5}>
   <Heading as="h1" mb={4}>
    Privacy and Policy
   </Heading>

   <Text fontSize="lg" mb={4}>
    Please ensure that you read and understand this Service Level Agreement
    before you use Ghoomo India applications. If you continue to browse and use
    our website and mobile apps, you are agreeing to comply with and be bound by
    the following Service Level Agreement that governs the relationship between
    Ghoomo India and you.
   </Text>

   <Divider my={6} />

   <Heading as="h2" size="md" mb={4}>
    1. Introduction
   </Heading>
   <Text mb={4}>
    Ghoomo India provides services for the purpose of providing lead generation
    to transportation service providers. Ghoomo India receives requests from the
    users of Ghoomo India's various applications such as the website, mobile
    apps, phone calls, and other means. Ghoomo India transfers these requests to
    the authorized transportation provider/ "Vendor/Driver". The "Vendor/Driver"
    seeks, receives, and fulfills the requests provided to them from Ghoomo
    India.
   </Text>

   <Heading as="h2" size="md" mb={4}>
    2. Agreement Overview
   </Heading>
   <Text mb={4}>
    The "Vendor/Driver" acknowledges and agrees that Ghoomo India is only a
    technology platform to provide various transportation or logistics services
    through its Vendors who are independent third-party contractors not employed
    by Ghoomo India, and that Ghoomo India does not provide transportation
    services or function as a transportation carrier or operate as an agent for
    the transportation of passengers.
   </Text>

   <Divider my={6} />

   <Heading as="h2" size="md" mb={4}>
    3. Binding Terms
   </Heading>
   <Text mb={4}>
    Ghoomo India and the "Vendor/Driver" mutually agree to be bound by the
    following terms and conditions set forth in this Agreement.
   </Text>

   <Divider my={6} />

   <Heading as="h2" size="md" mb={4}>
    4. Definitions
   </Heading>

   <Heading as="h3" size="sm" mb={4}>
    4.1 "Driver"
   </Heading>
   <Text mb={4}>
    "Driver" means a principal, agent, employee, or contractor of the Vendor
    who: (a) fulfills the then-current Ghoomo India requirements to be an active
    driver using the Ghoomo India services; (b) is authorized by Ghoomo India to
    access the Ghoomo India services to provide transportation services on
    behalf of the Vendor or on their own account, as the case may be; and (c) is
    verified by the Vendor (except when acting on own account) and whose names
    are forwarded to Ghoomo India by the Vendors for passing off the leads to
    them directly.
   </Text>

   <Heading as="h3" size="sm" mb={4}>
    4.2 "Users"
   </Heading>
   <Text mb={4}>
    "Users" means a customer end user, not being a minor (under the age of 18
    years), of the various applications of Ghoomo India like the website, the
    mobile application, and call center numbers, for the purpose of obtaining
    transport services offered by Ghoomo India's transportation "Vendor/Driver".
    If the User is below the age of 18 (eighteen) years, he/she shall read these
    terms with their parent or legal guardian, and in such a case these terms
    shall be deemed to be a contract between Ghoomo India and User's legal
    guardian or parent and to the extent permissible under applicable laws,
    enforceable against the User. We reserve the right on a case-by-case basis
    to demand documented proof that User's parent or legal guardian's consent
    has been granted.
   </Text>

   <Heading as="h3" size="sm" mb={4}>
    4.3 "Vehicle"
   </Heading>
   <Text mb={4}>
    "Vehicle" means any vehicle owned, leased, or hired by the Vendor that: (a)
    fulfills the then-current Ghoomo India requirements for a vehicle on the
    Ghoomo India platform; and (b) is authorized by Ghoomo India for use by a
    Driver for the purpose of providing transportation services on behalf of the
    vendor.
   </Text>

   <Divider my={6} />

   <Heading as="h2" size="md" mb={4}>
    5. Vendor/Driver Relationship with Users
   </Heading>
   <Text mb={4}>
    Vendor acknowledges and agrees that Vendor's provision of transportation
    services to Users creates a legal and direct business relationship between
    Vendor and the User, to which Ghoomo India is not a party. Ghoomo India is
    not responsible or liable for the actions or inactions of the User in
    relation to the activities of Vendor, its Driver, or any Vehicle. Vendor
    shall have the sole responsibility for any obligations or liabilities of
    Users or third parties that arise from its provision of transportation
    services on Ghoomo India's platform. Vendor acknowledges and agrees that
    they and their Drivers are responsible for complying with all applicable
    laws regarding their provision of transportation services to Users.
   </Text>
  </Box>
 );
};

export default PrivacyPolicyPage;
