import { Box } from "@chakra-ui/react";
import React from "react";
import Home from "../Components/Home";
import Services from "../Components/Services";
const Homepage = () => {
 return (
  <Box>
   <Home />
   <Services />
  </Box>
 );
};

export default Homepage;
