import React from "react";
import { Route, Routes } from "react-router-dom";
import Cbfrom from "./Components/Cbfrom";
import Login from "./Components/Login";
import Homepage from "./Pages/Homepage";
import Footer from "./Components/Footer";
import PrivateRoute from "./Components/PrivateRoute";
import Navbar from "./Components/Navbar";
import RegisterComponent from "./Components/RegisterComponent";
import RideListingPage from "./Components/RideListingPage";

function App() {
 return (
  <React.Fragment>
   <Navbar />
   <Routes>
    {/* Public routes */}
    <Route path="/" element={<Homepage />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<RegisterComponent />} />

    {/* Protected routes */}
    <Route element={<PrivateRoute />}>
     <Route path="/live" element={<RideListingPage />} />
     <Route path="/cbfrom" element={<Cbfrom />} />
    </Route>
   </Routes>
   <Footer />
  </React.Fragment>
 );
}

export default App;

// import React from "react";
// import { Route, Routes } from "react-router-dom"; // Import BrowserRouter
// import Cbfrom from "./Components/Cbfrom";
// import Login from "./Components/Login";
// import Homepage from "./Pages/Homepage";
// import Footer from "./Components/Footer";
// import PrivateRoute from "./Components/PrivateRoute"; // Import PrivateRoute
// import Navbar from "./Components/Navbar";
// import RegisterComponent from "./Components/RegisterComponent";
// import RideListingPage from "./Components/RideListingPage";

// function App() {
//  return (
//   <React.Fragment>
//    <Navbar />
//    <Routes>
//     {/* Public routes */}
//     <Route path="/" element={<Homepage />} />
//     <Route path="/login" element={<Login />} />
//     <Route path="/register" element={<RegisterComponent />} />

//     {/* Protected routes */}
//     <Route element={<PrivateRoute />}>
//      {/* <Route path="/cbfrom" element={<Cbfrom />} /> */}
//      <Route path="/live" element={<RideListingPage />} />
//     </Route>
//    </Routes>
//    <br />
//    <br />
//    <Footer />
//   </React.Fragment>
//  );
// }

// export default App;

// // import React from "react";
// // // import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// // import Cbfrom from "./Components/Cbfrom";
// // import Login from "./Components/Login";
// // import Homepage from "./Pages/Homepage";
// // import Liverider from "./Components/Liverider";
// // import Footer from "./Components/Footer";
// // import PrivateRoute from "./Components/PrivateRoute"; // Import PrivateRoute
// // import { Route, Routes } from "react-router-dom";
// // import Navbar from "./Components/Navbar";
// // import RegisterComponent from "./Components/RegisterComponent";
// // import RideListingPage from "./Components/RideListingPage";

// // function App() {
// //  return (
// //   <React.Fragment>
// //    <Navbar />
// //    <Routes>
// //     {/* Public routes */}
// //     <Route path="/" element={<Homepage />} />
// //     <Route path="/login" element={<RegisterComponent />} />

// //     {/* Protected routes */}
// //     <Route element={<PrivateRoute />}>
// //      <Route path="/cbfrom" element={<Cbfrom />} />
// //      <Route path="/live" element={<RideListingPage />} />
// //     </Route>
// //    </Routes>
// //    <br />
// //    <br />
// //    <Footer />
// //   </React.Fragment>
// //  );
// // }

// // export default App;
