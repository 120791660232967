import React from "react";
import { Box, Text, Image, VStack, HStack, Link, Icon } from "@chakra-ui/react";
import { FaPhone, FaMapMarkerAlt, FaTaxi } from "react-icons/fa";
import img1 from "../Images/Separator.png";

function Footer() {
 return (
  <div>
   <Image src={img1} alt="Separator" />

   {/* Footer Section */}
   <Box bg="black" color="white" py={10} px={5}>
    <HStack
     justifyContent={["center", "space-between"]} // Center content on mobile, space between on larger screens
     alignItems="flex-start"
     flexWrap="wrap"
     flexDirection={["column", "row"]} // Stack vertically on small screens, horizontally on larger screens
     spacing={[4, 10]} // Adjust spacing for small and large screens
    >
     {/* About Us Section */}
     <VStack alignItems="flex-start" spacing={4} mb={6} w={["100%", "30%"]}>
      <Text
       color="#FFC61A"
       fontFamily="Fira Sans"
       fontSize={["20px", "22px"]}
       fontWeight="bold"
      >
       ABOUT US
      </Text>
      <Text fontFamily="Fira Sans" fontSize={["14px", "16px"]}>
       At Ghum India, we believe in offering unmatched services to both car
       owners and passengers. Our platform bridges the gap between drivers and
       customers, ensuring quality, comfort, and convenience.
      </Text>
     </VStack>

     {/* Explore Section */}
     <VStack alignItems="flex-start" spacing={4} mb={6} w={["100%", "20%"]}>
      <Text
       color="#FFC61A"
       fontFamily="Fira Sans"
       fontSize={["20px", "22px"]}
       fontWeight="bold"
      >
       EXPLORE
      </Text>
      <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
       Gallery
      </Link>
      <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
       Testimonials
      </Link>
      <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
       Services
      </Link>
      <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
       Shortcodes
      </Link>
     </VStack>

     {/* Contact Us Section */}
     <VStack alignItems="flex-start" spacing={4} mb={6} w={["100%", "20%"]}>
      <Text
       color="#FFC61A"
       fontFamily="Fira Sans"
       fontSize={["20px", "22px"]}
       fontWeight="bold"
      >
       CONTACT US
      </Text>
      <HStack>
       <Icon as={FaPhone} color="#FFC61A" />
       <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
        9560262267
       </Link>
      </HStack>

      {/* Optional Fields: Uncomment if needed */}
      {/* 
            <HStack>
              <Icon as={FaMapMarkerAlt} color="#FFC61A" />
              <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
                Address: 43 2-nd Avenue, New York, NY 29004-7153
              </Link>
            </HStack>
            <HStack>
              <Icon as={FaTaxi} color="#FFC61A" />
              <Link fontFamily="Fira Sans" fontSize={["14px", "16px"]} href="#">
                Get Taxi
              </Link>
            </HStack>
            */}
     </VStack>
    </HStack>
   </Box>
  </div>
 );
}

export default Footer;
