import React, { useState } from "react";
import {
  Box,
  Input,
  Button,
  Text,
  HStack,
  VStack,
  Image
} from "@chakra-ui/react";
import img4 from "../Images/car.png";
import img1 from "../Images/Date.png";
import img2 from "../Images/Time.png";
import img3 from "../Images/Filter.png";
import axios from "axios";

function Cbfrom() {
  const data = { from: "", pickup: "", to: "", fare: "" };
  const [inputData, setInputData] = useState(data);

  function handleData(e) {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
    console.log(inputData);
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios.post("http://localhost:8082/user", inputData)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <Box
      bg="white"
      w="50vw"
      h="33vh"
      p={5}
      boxShadow="lg"
      borderRadius="md"
      margin="auto"
      mt="14%"
      opacity="0.9"
      bgColor="#FFFBF3"
      display="flex"
      justifyContent="space-between"
    >
      <Box w="27vw" h="30vh">
        <VStack align="start">
          <Text fontFamily="Kaisei Decol" fontSize="29px">
            From
          </Text>
          <Input
            variant="flushed"
            w="23vw"
            fontFamily="Kaisei Decol"
            fontSize="19px"
            placeholder="Start typing city - e.g. Bangalore"
            name="from"
            value={inputData.from}
            onChange={handleData}
          />
        </VStack>
        <VStack align="start" mt="8%">
          <Text fontFamily="Kaisei Decol" fontSize="29px">
            Pick Up
          </Text>
          <HStack>
            <Input
              variant="flushed"
              w="23vw"
              fontFamily="Kaisei Decol"
              fontSize="19px"
              placeholder="22-06-2024"
              name="pickup"
              value={inputData.pickup}
              onChange={handleData}
            />
            <Image src={img1} alt="Calendar Icon" />
            <Image src={img2} alt="Time Icon" />
          </HStack>
        </VStack>
      </Box>

      <Box w="30vw" h="30vh">
        <VStack align="start">
          <Text fontFamily="Kaisei Decol" fontSize="22px" mb="-4%" ml="6%">
            To
            <Image src={img4} alt="Car Icon" style={{ marginLeft: '245%' }} />
          </Text>
          <Input
            variant="flushed"
            w="23vw"
            fontFamily="Kaisei Decol"
            fontSize="19px"
            placeholder="Start typing city - e.g. Delhi"
            ml="9"
            name="to"
            value={inputData.to}
            onChange={handleData}
          />
        </VStack>
        <VStack align="start" mt="8%" ml="6%">
          <Text fontFamily="Kaisei Decol" fontSize="29px">
            Fare
          </Text>
          <HStack>
            <Input
              variant="flushed"
              w="20vw"
              fontFamily="Kaisei Decol"
              fontSize="19px"
              placeholder="₹2456/-"
              name="fare"
              value={inputData.fare}
              onChange={handleData}
            />
            <Image src={img3} alt="Filter Icon" />
          </HStack>
        </VStack>
        <Button
          type="submit" // Ensure the button triggers form submission
          bg="#FAB519"
          w="11vw"
          h="5vh"
          justifyContent="center"
          alignSelf="flex-start"
          mt={2}
          onClick={handleSubmit}
        >
          EXPLORE CABS
        </Button>
      </Box>
    </Box>
  );
}

export default Cbfrom;
